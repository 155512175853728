import HomePage from './pages/HomePage';
import HomeFeature from './Components/HomeFeature'
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import HomeFaq from './Components/HomeFaq'
import HomeContact from './Components/HomeContact';
import HomePricing from './Components/HomePricing';
import Blog from './pages/Blog'
import $ from 'jquery';
import Feature from './pages/Feature';
import Pricing from './pages/Pricing';
import About from './pages/About';
import Contact from './pages/Contact';
import BlogDetails from './pages/BlogDetail';
import Footer from './Components/Footer';
import FeatureDetail from './pages/FeatureDetail';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SupportPolicy from './pages/SupportPolicy'
import TermsandConditions from './pages/TermsandConditions'
import Faq from './pages/Faq';
import ViewsFeedbackDetail from './pages/ViewsFeedbackDetail';
import PricingDetails from './pages/PricingDetails';
import HybridRenty from './pages/HybridRenty';
import HybridGateKeeper from './pages/HybridGateKeeper';
import HybridHandyMan from './pages/HybridHandyMan';
import HowItsWork from './pages/HowItsWork';
import React, { useEffect, useState } from 'react';
import PricingDetailsIn from './pages/PricingDetailsIn';
import NotFound from './pages/NotFound';
import DataCollectionAndSecurity from './pages/DataCollectionAndSecurity';
import SignupPage from "./pages/SignupPage";


function App() {

  // const [userLocation, setUserLocation] = useState('');
  // const location = useLocation();
  // const navigate = useNavigate();
  // const  isUsRoute = location.pathname.startsWith('/us');
  // const isInRoute=location.pathname.startsWith('/in');

  // useEffect(() => {
  //   const getLocation = () => {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  
  //         const isUserInIndia = latitude > 8.4 && latitude < 37.6 && longitude > 68.7 && longitude < 97.25;
  //         const isUserInUS = latitude > 24.396308 && latitude < 49.384358 && longitude > -125.000000 && longitude < -66.934570;
  
  //         if (isInRoute) {
  //           setUserLocation(isUserInIndia ? 'in' : 'in');
  //         } else if (isUsRoute) {
  //           setUserLocation(isUserInUS ? 'us' : 'us');
  //         } else {
  //           setUserLocation( isUserInIndia ? 'in' : isUserInUS ? 'us' : '');
  //         }
  
  //         // Redirect to the appropriate default route
  //         if (!isInRoute && !isUsRoute) {
  //           navigate(`/${userLocation}`, { replace: true });
  //         }
  //       },
  //       (error) => {
  //         console.error('Error getting user location:', error);
  //         // If there's an error, set the default location to 'us'
  //       }
  //     );
  //   };
  
  //   getLocation();
  // }, [isUsRoute, isInRoute, userLocation, navigate]);
  

  // if (!userLocation) {
  //   return <div></div>;
  // }

//   const navigate = useNavigate();

  
//   // Redirect /in to /#/in
//   const redirectToHashIn = () => {
//     const { pathname } = window.location;
// const lowercasePath = pathname ? pathname.toLowerCase() : '';

// if (lowercasePath === '/in') {
//   // Use the History API to update the URL
//   window.history.replaceState({}, '', '/in#/in');
//   // Optionally, trigger a popstate event if needed
//   window.dispatchEvent(new Event('popstate'));
// }
//   };

//   // Redirect /us to /#/us
//   const redirectToHashUs = () => {
//     const { pathname } = window.location;
// const lowercasePath = pathname ? pathname.toLowerCase() : '';

// if (lowercasePath === '/us') {
//   // Use the History API to update the URL
//   window.history.replaceState({}, '', '/us#/us');
//   // Optionally, trigger a popstate event if needed
//   window.dispatchEvent(new Event('popstate'));
// }
//   };

//   // Call the redirection functions on component mount
//   React.useEffect(() => {
//     redirectToHashIn();
//     redirectToHashUs();
//   }, []);

//   useEffect(() => {
//     const storedRoute = sessionStorage.getItem('currentRoute');
//     const isPageRefresh = performance.navigation.type === performance.navigation.TYPE_RELOAD;
//     const isStoredRouteDifferent = storedRoute && storedRoute !== window.location.href;
  
//     // Check if it's the initial load
//     const isInitialLoad = !isPageRefresh && !isStoredRouteDifferent;
  
//     if (isPageRefresh && isStoredRouteDifferent && storedRoute !== window.location.href) {
//       window.location.href = storedRoute; // Redirect to the stored route on page refresh
//     }
  
//     // Store the current route only if it's not the initial load
//     if (!isInitialLoad) {
//       const handleNavigation = () => {
//         sessionStorage.setItem('currentRoute', window.location.href);
//       };
  
//       // Add event listeners for navigation
//       window.addEventListener('unload', handleNavigation);
  
//       // Remove event listeners on unmount
//       return () => {
//         window.removeEventListener('unload', handleNavigation);
//       };
//     }
//   }, []);

 return (
    <div className="App">
         <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="/featuresdetail" element={<Feature />} />
      {/* <Route path="/pricing" element={<Pricing />} /> */}
      {/* <Route path="/faq" element={<HomeFaq />} /> */}
      <Route path="/contactus" element={<Contact />} />
      <Route path="/blog" element={<Blog />} />
      {/* <Route path="/pricingdetail" element={<Pricing />} /> */}
      <Route path='/faqdetail' element={<Faq />} />
      <Route path='/vfbdetails' element={<ViewsFeedbackDetail />} />
      <Route path="/blogdetail" element={<BlogDetails />} />
      <Route path="/featuredetail" element={<FeatureDetail />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/supportPolicy" element={<SupportPolicy />} />
      <Route path="/termsofservice" element={<TermsandConditions />} />
      <Route path='/pricing' element={<PricingDetails />} />
      <Route path='/hybridrenty' element={<HybridRenty />} />
      <Route path='/hybridgatekeeper' element={<HybridGateKeeper />} />
      <Route path='/hybridhandyman' element={<HybridHandyMan />} />
      <Route path='/howitwork' element={<HowItsWork />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/datacollectionandsecurity" element={<DataCollectionAndSecurity/>}/>
      <Route path="/signup" element={<SignupPage/>}/>
</Routes> 
{/*{userLocation === 'in' && ( */}
{/* <Routes>
  <Route path="/in/*" element={
   
    <>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="featuresdetail" element={<Feature />} /> 
      {/* <Route path="pricing" element={<Pricing />} /> 
      <Route path="faq" element={<HomeFaq />} />
      <Route path="contactus" element={<Contact />} />
      <Route path="blog" element={<Blog />} />
      {/* <Route path="pricingdetail" element={<Pricing />} /> 
      <Route path="faqdetail" element={<Faq />} />
      <Route path="vfbdetails" element={<ViewsFeedbackDetail />} />
      <Route path="blogdetail" element={<BlogDetails />} />
      <Route path="featuredetail" element={<FeatureDetail />} />
      <Route path="privacypolicy" element={<PrivacyPolicy />} />
      <Route path="supportPolicy" element={<SupportPolicy />} />
      <Route path="termsofservice" element={<TermsandConditions />} />
      <Route path="pricing" element={<PricingDetailsIn />} />
      <Route path="hybridrenty" element={<HybridRenty />} />
      <Route path="hybridgatekeeper" element={<HybridGateKeeper />} />
      <Route path="hybridhandyman" element={<HybridHandyMan />} />
      <Route path="howitwork" element={<HowItsWork />} />
      </Routes>
    </>
  } />
</Routes> */}
  {/* // )}   */}


   
{/* {userLocation === 'us' && ( */}
{/* <Routes>
  <Route path="/us/*" element={
    <>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="about" element={<About />} />
      <Route path="featuresdetail" element={<Feature />} />
      {/* <Route path="pricing" element={<Pricing />} />
      <Route path="faq" element={<HomeFaq />} />
      <Route path="contactus" element={<Contact />} />
      <Route path="blog" element={<Blog />} />
      {/* <Route path="pricingdetail" element={<Pricing />} />
      <Route path="faqdetail" element={<Faq />} />
      <Route path="vfbdetails" element={<ViewsFeedbackDetail />} />
      <Route path="blogdetail" element={<BlogDetails />} />
      <Route path="featuredetail" element={<FeatureDetail />} />
      <Route path="privacypolicy" element={<PrivacyPolicy />} />
      <Route path="supportPolicy" element={<SupportPolicy />} />
      <Route path="termsofservice" element={<TermsandConditions />} />
      <Route path="pricing" element={<PricingDetails />} />
      <Route path="hybridrenty" element={<HybridRenty />} />
      <Route path="hybridgatekeeper" element={<HybridGateKeeper />} />
      <Route path="hybridhandyman" element={<HybridHandyMan />} />
      <Route path="howitwork" element={<HowItsWork />} />
      </Routes>
    </>
  } />
</Routes> */}
 {/* )}  */}

 {/* <Routes>
 <Route
          path="/"
          element={<Navigate to={`/${userLocation}`} replace />}
        />
</Routes>   */}


 {/* <Footer/> */}

    </div>
  );
}

export default App;