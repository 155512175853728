
import {
  Button,
  Card,

  CardContent,
 
  IconButton,
  Typography,
} from "@mui/material";
import {  RocketLaunch, Telegram } from "@mui/icons-material";

import {
  BASE_URL,
  PRICINGHERO,
} from "../utils/ApplicationEndpoints";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";


function PricingHeader() {

    const [data, setData] = useState([]);
  
    const fetchData = useCallback(async () => {
      try {
        const response = await axios.get(BASE_URL + PRICINGHERO);
        setData(response.data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching feature data:", error);
      }
    }, [data]);
  
    useEffect(() => {
      fetchData();
    }, [fetchData]);
  return (
    <div >
       {data.length > 0 ? (
          <div className="row">
            
            <div className="col-lg-4 col-md-6 col-sm-12">
              <Card
                sx={{ minWidth: 275, maxWidth: 345, minHeight: 200 }}
                className="mt-5 mb-5  first-item wow fadeInUp"
                data-wow-delay=".15s"
                style={{ transition: "transform 0.3s" }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "scale(1.20)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >
                <CardContent  
               >
                  <div style={{ textAlign: "center" }}>
                    
                      <Telegram />
                   
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <h4 style={{ color: "black" }}>{data[0].topic}</h4>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Typography style={{ color: "black" }}>
                      {data[0].description}
                    </Typography>
                    <h3>
                      <b>${data[0].amount}</b>
                    </h3>
                  </div>

                  <div style={{ textAlign: "center" }} className="pt-2">
                    <Button
                      variant="contained"
                      onClick={() => {
                        window.open(data[1].buttonUrl, "_blank");
                      }}
                    >
                      Schedule Demo
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <Card
                sx={{ minWidth: 275, maxWidth: 345, minHeight: 200 }}
                className="mt-5 mb-5 first-item wow fadeInUp"
                data-wow-delay=".15s"
                style={{ transition: "transform 0.3s" }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "scale(1.20)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >
                <CardContent>
                  <div style={{ textAlign: "center" }}>
                    <IconButton>
                      <RocketLaunch />
                    </IconButton>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <h4 style={{ color: "black" }}>{data[1].topic}</h4>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Typography style={{ color: "black" }}>
                      {data[1].description}
                    </Typography>
                    <h3>
                      <b>${data[1].amount}</b>
                    </h3>
                  </div>
                  <div style={{ textAlign: "center" }} className="pt-2">
                    <Button
                      variant="contained"
                      onClick={() => {
                        window.open(data[1].buttonUrl, "_blank");
                      }}
                    >
                      Schedule Demo
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <Card
                sx={{ minWidth: 275, maxWidth: 345, minHeight: 200 }}
                className="mt-5 mb-5 first-item wow fadeInUp"
                data-wow-delay=".15s"
                style={{ transition: "transform 0.3s" }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "scale(1.20)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >
                <CardContent>
                  <div style={{ textAlign: "center" }}>
                    <IconButton>
                      <Telegram />
                    </IconButton>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <h4 style={{ color: "black" }}>{data[2].topic}</h4>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Typography style={{ color: "black" }}>
                      {data[2].description}
                    </Typography>
                    <h3>
                      <b>${data[2].amount}</b>
                    </h3>
                  </div>
                  <div style={{ textAlign: "center" }} className="pt-2">
                    <Button
                      variant="contained"
                      onClick={() => {
                        window.open(data[1].buttonUrl, "_blank");
                      }}
                    >
                      Schedule Demo
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        ) : null}
    </div>
  )
}

export default PricingHeader
 