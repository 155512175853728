// export const BASE_URL="http://localhost:8099/hhoa-home/api/v1/";
export const BASE_URL = "https://api.hybridhoa.com/hhoa-home/api/v1/";
// export const BASE_URL = process.env.BASE_URL;
export const BASE_URL_SIGNUP = "https://systemapi.hybridhoa.com/hhoa-system-portal/api/v1/";

// export const BASE_URL= "http://localhost:7777/api/v1/";

export const HERO = "hero";
export const ABOUT = "about";
export const CONTACT = "contact";
export const MAINFB = "fb";
export const MAINFEATURE = "mainfeature";
export const FEATURECARD = "featurecard";
export const VIEWSFEEDBACK = "fbcard";
export const IMAGE = "image/";
export const FEEDBACK = "fb";
export const FEEDBACKCARD = "fbcard";
export const FAQ = "faq";
export const FAQ_CARD = "faqcard";
export const PRIVACY_POLICY = "privacypolicy";
export const TERMS_CONDITIONS = "securitypolicy";
export const BLOG = "blog";
export const BLOG_BY_TOPIC = "blog/topic";
export const SUBSCRIBE = "subscribe";
export const PRICINGHERO = "pricinghero";
export const PRICINGCONTENT = "pricingcontent";
export const TIMELINE = "timeline";
export const HOWITWORKS = "instructions";
export const SUBOPSTIONS = "subinstructions";
export const FEATURE_NOTIFICATIONS = "popup";

export const FEATURE_CATEGORY = "featurecategory";
export const FEATURE_CARD = "featurecard";
export const IMAGE_FEATURE = "image/";

export const DATACOLLECTIONANDSECURITY="/datacollection";

export const GROUP_ADD_SIGNUP="signup";