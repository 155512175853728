import React from 'react'
import Header from '../Components/Header'
import PageHeader from '../Components/PageHeader'
import HomeContact from '../Components/HomeContact'
import { Helmet } from "react-helmet";
import Footer from '../Components/Footer';
import Signup from '../Components/Signup'
function Contact() {
  return (
    <div>
<Helmet>

<title>Sign Up</title>
<meta name="title" content="Sign Up" />
<meta name="description" content="Reach out to us for support, inquiries, or assistance. Contact HybridHOA and let's create a thriving community together" />


<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.hybridhoa.com/contactus" />
<meta property="og:title" content="Contact us" />
<meta property="og:description" content="Reach out to us for support, inquiries, or assistance. Contact HybridHOA and let's create a thriving community together" />

<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.hybridhoa.com/contactus" />
<meta property="twitter:title" content="Contact us" />
<meta property="twitter:description" content="Reach out to us for support, inquiries, or assistance. Contact HybridHOA and let's create a thriving community together" />
<meta property="og:image" content="../assets/img/preview.png" />
        <link rel='canonical' href='https://hybridhoa.com/contactus' />
        <meta name="viewport" content="width=device-width,initial-scale=1"></meta>

</Helmet>
      <Header/>
      <PageHeader title=" Sign Up to Enter Our Community"/>
      <Signup />
      <Footer />
    </div>
  )
}

export default Contact
