import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Header from "../Components/Header";
import PageHeader from "../Components/PageHeader";
// import gifimage7 from '../Images/manage.gif'
// import gifimage8 from '../Images/all_in-2_1.gif'
// import gifimage9 from '../Images/image_gif.gif'
import "../js/main.js";
// import image_3 from '../Images/Contact+us.gif'
// import image_7 from '../Images/price.gif'
import {
  BASE_URL,
  HOWITWORKS,
  SUBOPSTIONS,
  IMAGE,
} from "../utils/ApplicationEndpoints";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import "./HowItWorks.css";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer.js";
import { Link } from "react-router-dom";

const HowItsWork = () => {
  const [steps, setstep] = useState([]);

  const [expandedStep, setExpandedStep] = useState(null);

  const [showScrollButton, setShowScrollButton] = useState(false);


  const handleExpandStep = (index) => {
    setExpandedStep(index);
  };

  // const handleCloseExpandedStep = () => {
  //   setExpandedStep(null);
  // };
  const handleCloseExpandedStep = (event) => {
    // Check if the clicked element is the "Next" button
    // const isNextButton = event.target.classList.contains('navigation-button') && event.target.innerHTML.includes('Next');
    // if (!isNextButton) {
    //   setExpandedStep(null);
    // }
  };

  const fetchDatarespones = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + HOWITWORKS);
      const response1 = await axios.get(BASE_URL + SUBOPSTIONS);

      // Group sub-options by order value
      const groupedSubOptions = response1.data.reduce((groups, subOption) => {
        const order = subOption.orders;
        if (!groups[order]) {
          groups[order] = [];
        }
        groups[order].push(subOption);
        return groups;
      }, {});

      // Filter and merge arrays based on order value
      const mergedData = response.data.map((item) => {
        const subOptions = groupedSubOptions[item.orders] || [];

        return {
          image: item.imageurl,
          description: item.description,
          header: item.topic,
          order: item.orders,
          alt:item.imgAlternative,
          subOption: subOptions.map((subOption) => ({
            image: subOption.imageurl,
            description: subOption.description,
            header: subOption.topic,
            order: subOption.orders,
            featuresOrder: subOption.instructionsOrder,
            alt: subOption.imgAlternative,
           

          })),
          
        };
        
      });
      setstep(mergedData);
      
      console.log(mergedData);
    } catch (error) {
      console.error("Error fetching feature data:", error);
    }
  }, []);

  // const steps = [
  //   {
  //     image: gifimage7,
  //     // description: "Simplify community management with HybridHoa.com - Effortless and connected.",
  //     header: 'Know the Product:',
  //     // buttton:true,
  //     subOption: [
  //       {
  //         id: 1,
  //         // image: image_1,
  //         header: 'Introduction to HybridHOA',
  //         description:
  //         "Discover how HybridHOA can streamline your homeowner association management with our all-in-one platform.",
  //         featuresOrder: false,
  //       },

  //     ],
  //   },

  //   {
  //     image:  gifimage9,
  //     // description: 'Basic - Extended - Premium ',
  //     header:"Getting Details from the Customer",
  //     buttton:false,
  //     subOption: [
  //       {
  //         id: 1,
  //         image: image_3,
  //         header: " Creating an Account",
  //         description:
  //         "Take control of your HOA management by creating a personalized account with HybridHOA.",
  //         featuresOrder:false,
  //       },

  //     ],
  //   },
  //   {
  //     image: gifimage8,
  //     // description: 'If Interested - Technical support will create an account for you ',
  //     header:"Account Payment Gateway Activation",
  //     buttton:false,
  //     subOption: [
  //       {
  //         id: 1,
  //         header:"Payment Options",
  //         image:image_7,
  //         description:
  //         "Choose from our secure and convenient payment options to activate your HybridHOA account.",
  //         featuresOrder:false,
  //       },

  //     ],
  //   },

  // ];

  const [features, setFeatures] = useState([]);
  const [featuresOrder, setFeaturesOrder] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = steps[expandedStep];
      setFeatures(response.subOption);
      const orderValues = response.subOption.map((item) => item.featuresOrder);
      setFeaturesOrder(orderValues);
    } catch (error) {
      console.error("Error fetching feature data:", error);
    }
  }, [steps,expandedStep]);

  useEffect(() => {
    fetchData();
    fetchDatarespones();
  }, [fetchData,fetchDatarespones]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const shouldShowButton = scrollTop > 200; // Adjust the value as needed
      setShowScrollButton(shouldShowButton);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchDatarespones]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollButtonStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: "#333",
    color: "#fff",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  // const handlePreviousStep = () => {
  //   // if (expandedStep > 0) {
  //   //   setExpandedStep((prevStep) => prevStep - 1);
  //   // }

  // };

  const handlePreviousStep = () => {
    if (expandedStep > 0) {
      const prevStep = expandedStep - 1;
      setExpandedStep(prevStep);
      setFeatures(steps[prevStep].subOption);
      const orderValues = steps[prevStep].subOption.map(
        (item) => item.featuresOrder
      );
      setFeaturesOrder(orderValues);
    }
  };

  const handleNextStep = () => {
    if (expandedStep < steps.length - 1) {
      const nextStep = expandedStep + 1;
      setExpandedStep(nextStep);
      setFeatures(steps[nextStep].subOption);
      const orderValues = steps[nextStep].subOption.map(
        (item) => item.featuresOrder
      );
      setFeaturesOrder(orderValues);
    }
  };

  return (
    <div>
      <Helmet>
        <title>How It Works</title>
        <meta name="title" content="How It Works" />
        <meta
          name="description"
          content="Learn efficient HOA onboarding with our step-by-step guide. Streamline processes and ensure smooth transitions"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hybridhoa.com/howitwork" />
        <meta property="og:title" content="How It Works" />
        <meta
          property="og:description"
          content="Learn efficient HOA onboarding with our step-by-step guide. Streamline processes and ensure smooth transitions"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.hybridhoa.com/howitwork"
        />
        <meta property="twitter:title" content="How It Works" />
        <meta
          property="twitter:description"
          content="Learn efficient HOA onboarding with our step-by-step guide. Streamline processes and ensure smooth transitions"
        />
        <link rel='canonical' href='https://www.hybridhoa.com/howitwork' />
<meta name="viewport" content="width=device-width,initial-scale=1"></meta>
<meta property="og:image" content="../assets/img/preview.png" />
      </Helmet>
      <Header />
      <PageHeader title="Join effortlessly through simple steps and become a part of our community." />
      <Container className="py-4">
        {/* <h1 className="text-center-inline" > A Step-by-Step Guide </h1> */}
        <Row>
          <Col xs={14} md={9}>
            <Row>
              {steps.map((step, index) => (
                <Col key={index} xs={12} md={6} lg={4} className="text-center">
                  <Card
                  
                    className="expanded-card"
                    onClick={() => handleExpandStep(index)}
                  >
                    <Card.Img
                      variant="top"
                      src={`${BASE_URL}${IMAGE}${step.image}`}
                      alt={step.alt}
                      loading="eager"
                      width={'actualWidth'}
                      height={'actualHeight'}
                      title={step.alt}
                      className="img-fluid-card"
                    />
                    <Card.Body>
                      <Card.Title>{`Step ${index + 1}`}</Card.Title>
                      <Card.Text className="heder_text">
                        {step.header} 
                      </Card.Text>
                      <div></div>
                      {/* <Card.Text>{step.description}</Card.Text> */}
                      <div className="sub-option-details">
                        {step.subOption.map((option, optionIndex) => (
                          <div key={optionIndex} className="sub-option">
                            {/* Render your sub-option details here */}
                          </div>
                        ))}
                      </div>

                      <div className="view_more" style={{cursor:'pointer'}}>
                        <p className="new_view">view more</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          <Col
            xs={12}
            md={3}
            className="d-flex align-items-center justify-content-center"
          >
            <Link
                            to={"/signup"}
                             className="ud-main-btn ud-white-btn ud-main-btns"
                            replace
                            style={{
                              backgroundColor: "white",
                              color: "#2748b4",
                              marginLeft: 50,
                              padding: "8px 16px", 
                              fontSize: "14px", 
                            }}
                          >
                            Sign up
                          </Link>
          </Col>
        </Row>
      </Container>
      {expandedStep !== null && (
        <div
          className="expanded-card-overlay"
          onClick={handleCloseExpandedStep}
        >
          <React.Fragment>
            <div className="container">
              <br />
              <div className="row">
                {features.map((feature, index) => {
                 
                  console.log("image name"+feature);
                  const imageUrls = Array.isArray(feature.imageUrl)
                    ? feature.imageUrl
                    : [feature.image];

                  const renderImages = () =>
              
                    imageUrls.map((img, imgIndex) => (
                      
                      <img
                        key={imgIndex}
                        src={`${BASE_URL}${IMAGE}${img}`}
                        className="img-fluid-1"
                        alt={feature.alt}
                        style={{
                          alignContent: "center",
                          alignItems: "center",
                        }}
                        width="450px"
                        loading="eager"
                        // width={'actualWidth'}
                        height={'actualHeight'}
                        title={feature.alt}
                      />
                    ));

                  const renderDescription = () => {
                    const descriptionWithoutPTags = feature.description.replace(
                      /<\/?p>/g,
                      ""
                    ); // Remove <p> tags
                    return (
                      <div className="col-lg-6 benefits-mt-150">
                        <h2 className="para">{feature.header}</h2>
                        <p id="benefits-col-font-weight">
                          {descriptionWithoutPTags}
                          
                        </p>
                      </div>
                    );
                  };

                  return (
                    <React.Fragment key={index}>
                      {featuresOrder[index] ? (
                        <>
                          <div className="col-lg-6 pt-4">{renderImages()}</div>
                          {renderDescription()}
                        </>
                      ) : (
                        <>
                          {renderDescription()}

                          <div className="col-lg-6 pt-4"> {renderImages()}</div>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>

            <Row className="mt-4">
              <Col xs={12}>
                <div className="step-navigation">
                  <button
                    className="navigation-button"
                    disabled={expandedStep === 0}
                    onClick={handlePreviousStep}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Previous
                  </button>
                  <button
                    className="navigation-button"
                    disabled={expandedStep === steps.length - 1}
                    onClick={handleNextStep}
                  >
                    Next
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        </div>
      )}
      {showScrollButton && (
        <div style={scrollButtonStyle} onClick={handleScrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default HowItsWork;
