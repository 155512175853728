import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BASE_URL, CONTACT, GROUP_ADD_SIGNUP, BASE_URL_SIGNUP } from "../utils/ApplicationEndpoints";
import Swal from "sweetalert2";
import { Button, MenuItem, Select, Snackbar, TextField, FormControl, InputLabel, FormHelperText, Box, Typography, Tooltip } from "@mui/material";
import { Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import MuiAlert from "@mui/material/Alert";
import { Navigate, useLocation } from "react-router-dom";
import Header from "../Components/Header";
import { MarkEmailReadSharp } from "@mui/icons-material";
// import { isValidPhoneNumber } from "react-phone-number-input";
// import "react-phone-input-2/lib/material.css";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
// import PhoneInput from 'react-phone-number-input';
// import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css'; // Make sure to import the CSS file

import ReCAPTCHA from "react-google-recaptcha"
import { useNavigate } from 'react-router-dom';



function HomeContact() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch, setValue, setError,
    clearErrors, trigger,
  } = useForm();
  const recaptchaRef = React.useRef();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleHouseCountChange = (value) => {
    const houseCount = parseInt(value, 10) || 0; // Convert to integer or default to 0 if NaN
    const totalSubscriptionAmount = houseCount * 0.9;
    setValue('totalSubscriptionAmount', totalSubscriptionAmount.toFixed(2)); // Set the value with 2 decimal places
  };

  // Refs for the input fields
  const [captchaToken, setCaptchaToken] = useState(null); // To store the reCAPTCHA token
  const [captchaError, setCaptchaError] = useState('');
  const groupNameRef = useRef();
  const addressRef = useRef();
  const zipcodeRef = useRef();
  const groupAdminEmailRef = useRef();
  const groupAdminFirstNameRef = useRef();
  const groupAdminLastNameRef = useRef();
  const phoneRef = useRef();
  const houseCountRef = useRef();


  // Function to handle focusing on the first error field

  const onRecaptchaChange = (token) => {
    setCaptchaToken(recaptchaRef.current.getValue()); // Store the token when user completes the reCAPTCHA
    setCaptchaError('');    // Clear any previous error
  };
  const onSubmit = (data) => {

    console.log("clicked submit");
    console.log("dataaaaaaaaaaa" + JSON.stringify(data));
    console.log(data.groupAdminFirstName.trim());

    
    console.log(captchaToken);
    
    if(!captchaToken){
      console.log("inside captcha");
      setCaptchaError('Please complete the CAPTCHA before submitting.');
      // recaptchaRef.current.reset();
      return;
    }
   

    // if (data.address.trim() == '' || data.address == null) {
    //       setError("address", {
    //         type: "manual",
    //         message: "address is required",
    //       });

    //       return;
    //     }
    //     if (data.address.trim().length < 2) {
    //       setError("address", {
    //         type: "manual",
    //         message: "Please provide a minimum of 2 characters",
    //       });
    //       return;
    //     }
    //     if (data.groupAdminFirstName.trim() == '' || data.groupAdminFirstName == null) {
    //       setError("groupAdminFirstName", {
    //         type: "manual",
    //         message: "First name is required",
    //       });

    //       return;
    //     }
    //     if (data.groupAdminLastName.trim() == '' || data.groupAdminLastName == null) {
    //       setError("groupAdminLastName", {
    //         type: "manual",
    //         message: "Last name is required",
    //       });

    //       return;
    //     }


    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 30);

    const phoneNumber = parsePhoneNumberFromString(data.phone, 'US'); // You can specify the default country code here if needed
    const formattedPhoneNumber = phoneNumber ? phoneNumber.format('E.164') : data.phone;

    // Calculate endDate as 1 year after startDate
    const endDate = new Date(startDate);
    endDate.setFullYear(startDate.getFullYear() + 1);
    endDate.setDate(endDate.getDate() - 1);
    axios
      .post(BASE_URL_SIGNUP + GROUP_ADD_SIGNUP, {
        groupName: data.groupName.trim(),
        address: data.address.trim(),
        // email: data.email,
        contact: formattedPhoneNumber,
        zipCode: data.zipcode,
        groupAdminFirstName: data.groupAdminFirstName.trim(),
        groupAdminLastName: data.groupAdminLastName.trim(),
        groupAdminEmail: data.groupAdminEmail,
        price: data.totalSubscriptionAmount,
        houseCount: data.houseCount.trim(),
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
        // freeTrail:'30', 

      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {

          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data,
          }).then(() => {
            // This will be executed after the user clicks "OK"
            recaptchaRef.current.reset();
            navigate("/");
          });

          // setTimeout(() => {
          //   recaptchaRef.current.reset();
          //   navigate("/")
          // }, 2000)

        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setErrorMessage(error.response.data.message);
          setOpenSnackbar(true);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };


  useEffect(() => {
    if (errors.groupName) {
      groupNameRef.current.focus();
    } else
      if (errors.address) {
        addressRef.current.focus();
      } else
        if (errors.zipcode) {
          zipcodeRef.current.focus();
        } else
          if (errors.phone) {
            phoneRef.current.focus();
          } else
            if (errors.groupAdminFirstName) {
              groupAdminFirstNameRef.current.focus();
            } else
              if (errors.groupAdminLastName) {
                groupAdminLastNameRef.current.focus();
              } else
                if (errors.groupAdminEmail) {
                  groupAdminEmailRef.current.focus();
                } else
                  if (errors.houseCount) {
                    houseCountRef.current.focus();
                  }
  }, [errors])

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>

      <section id="contact" className="ud-contact" >
        <div className="container" style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="row align-items-center" style={{ width: '100%', maxWidth: '800px' }}>
            <div className="col-xl-12 col-lg-12" style={{ marginTop: "70px" }}>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                style={{ width: "100%" }}
              >
                <MuiAlert
                  severity="error"
                  onClose={handleSnackbarClose}
                  style={{ width: "100%" }}
                >
                  {errorMessage}
                </MuiAlert>
              </Snackbar>

              <div className="ud-contact-form-wrapper" style={{ textAlign: 'center' }}>
                <h3 className="ud-contact-form-title" style={{ color: '#363636' }}>Subscriber Info</h3>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row" style={{ justifyContent: 'center' }}>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <Controller
                        name="groupName"
                        control={control}
                        defaultValue=""

                        rules={{
                          required: { value: true, message: "HOA name is required" },
                          pattern: { value:/^[a-zA-Z ][a-zA-Z0-9 '/ ]*[a-zA-Z0-9 ]$/, message: "Please provide valid HOA name" },
                          minLength: { value: 2, message: "Please provide a minimum of 2 characters" },
                          maxLength: { value: 250, message: "Please provide maximum of 250 characters only" },
                        }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            onBlur={(e) => field.onChange(e.target.value.trim())}
                            label="HOA Name*"
                            error={Boolean(errors?.groupName?.message)}
                            helperText={errors?.groupName?.message}
                            inputRef={groupNameRef}
                          />
                        )}
                      />
                    </div>
                    &nbsp;
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <Controller
                        name="address"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: { value: true, message: "Address is required" },
                          pattern: { value: /^[a-zA-Z0-9,.\-\p{P} ]*$/, message: "Please provide valid Address" },
                          minLength: { value: 2, message: "Please provide a minimum of 2 characters" },
                          maxLength: { value: 250, message: "Please provide maximum of 250 characters only" },
                        }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            onBlur={(e) => field.onChange(e.target.value.trim())}
                            label="Address*"
                            error={Boolean(errors?.address?.message)}
                            helperText={errors?.address?.message}
                            inputRef={addressRef}
                          />
                        )}
                      />
                    </div>&nbsp;
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <Controller
                        name="zipcode"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: { value: true, message: "Zipcode is required" },
                          pattern: { value: /^\d+$/, message: "Please provide valid zipcode" },
                          minLength: { value: 5, message: "Please provide minimum 5 digits" },
                          maxLength: { value: 5, message: "Please provide maximum 5 digits" },

                        }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            onBlur={(e) => field.onChange(e.target.value.trim())}
                            label="Zipcode*"
                            error={Boolean(errors?.zipcode?.message)}
                            helperText={errors?.zipcode?.message}
                            inputRef={zipcodeRef}
                          />
                        )}
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: 30 }}>
                      <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: { value: true, message: "Phone number is required" },
                          pattern: {
                            value: /^(?:\+1\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                            message: "Invalid phone number"
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label="Phone*"
                            placeholder="+1 XXX XXX XXXX"
                            error={Boolean(errors?.phone?.message)}
                            helperText={errors?.phone?.message}
                            inputRef={phoneRef}
                          />
                        )}
                      />


                      {/* <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: { value: true, message: "Phone number is required" },
                          validate: {
                            isValidPhoneNumber: (value) => {
                              const phoneNumber = parsePhoneNumberFromString(value, 'US'); // Always use US country code
                              return phoneNumber && phoneNumber.isValid() || "Invalid phone number";
                            },
                          },
                        }}
                        render={({ field }) => (
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ marginRight: '16px', alignSelf: 'flex-start' }}>
                              Phone Number
                            </Typography>
                            <PhoneInput
                              {...field}
                              disableDropdown
                              defaultCountry="US"
                              country="us" // Default to US country code
                              international
                              countryCallingCodeEditable={false}
                              placeholder="Enter phone number"
                              value={field.value}
                              onChange={(value) => field.onChange(value.replace(/^\+1/, ''))} // Remove +1 if it's added
                              inputStyle={{
                                width: '100%',
                                height: '56px',
                                padding: '10.5px 14px',
                                borderColor: Boolean(errors?.phone?.message) ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                                borderRadius: '4px',
                                fontSize: '1rem',
                                paddingLeft: '60px',
                              }}
                              buttonStyle={{
                                borderRadius: '4px',
                                border: '1px solid rgba(0, 0, 0, 0.23)',
                              }}
                            />
                            {errors?.phone?.message && (
                              <FormHelperText error>{errors.phone.message}</FormHelperText>
                            )}
                          </Box>
                        )}
                      /> */}


                    </div>
                    {/* <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: 30 }}>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: { value: true, message: "Email is required" },
                          pattern: { value: /^(?!.*\.com.*\.com.*\.com)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: "Please enter a valid email address" },
                        }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label="Email*"
                            error={Boolean(errors?.email?.message)}
                            helperText={errors?.email?.message}
                          />
                        )}
                      />
                    </div> */}
                    &nbsp;
                    {/* <h5>User Info</h5> */}
                    <h3 className="ud-contact-form-title" style={{ color: '#363636' }}>User Info</h3>
                    &nbsp;


                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <Controller
                        name="groupAdminFirstName"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: { value: true, message: "First name is required" },
                          pattern: { value: /^[a-zA-Z ]*$/, message: "Please provide valid FirstName" },
                          minLength: { value: 1, message: "Please provide a minimum of 1 characters" },
                          maxLength: { value: 60, message: "Please provide a maximum of 60 characters" },
                        }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            onBlur={(e) => field.onChange(e.target.value.trim())}
                            label="First Name*"
                            error={Boolean(errors?.groupAdminFirstName?.message)}
                            helperText={errors?.groupAdminFirstName?.message}
                            inputRef={groupAdminFirstNameRef}
                          />
                        )}
                      />
                    </div>
                    &nbsp;
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <Controller
                        name="groupAdminLastName"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: { value: true, message: "Last name is required" },
                          pattern: { value: /^[a-zA-Z ]*$/, message: "Please provide valid last name" },
                          minLength: { value: 1, message: "Please provide a minimum of 1 characters" },
                          maxLength: { value: 60, message: "Please provide a maximum of 60 characters" },
                        }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            onBlur={(e) => field.onChange(e.target.value.trim())}
                            label="Last Name*"
                            error={Boolean(errors?.groupAdminLastName?.message)}
                            helperText={errors?.groupAdminLastName?.message}
                            inputRef={groupAdminLastNameRef}
                          />
                        )}
                      />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: 30 }}>
                      <Controller
                        name="groupAdminEmail"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: { value: true, message: "Email is required" },
                          pattern: { value:/^[a-zA-Z0-9!#$%&'*+=?.+^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: "Please enter a valid email address" },
                        }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            onBlur={(e) => field.onChange(e.target.value.trim())}
                            label="Email*"
                            error={Boolean(errors?.groupAdminEmail?.message)}
                            helperText={errors?.groupAdminEmail?.message}
                            inputRef={groupAdminEmailRef}
                          />
                        )}
                      />
                    </div>
                    &nbsp;
                    {/* <h5>Subscription Details</h5> */}
                    <h3 className="ud-contact-form-title" style={{ color: '#363636' }}>Subscription Details</h3>
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: 30 }}>
                      <FormControl fullWidth error={Boolean(errors.planTerm)}>
                        <InputLabel>Subscription Plan Term</InputLabel>
                        <Controller
                          name="planTerm"
                          control={control}
                          rules={{ required: "Plan Term is required" }}
                          defaultValue="1_YEAR"
                          render={({ field }) => (
                            <Tooltip title="This Field is disable,You can Upgrade your plan term by contacting the System admin after completed the Sign-Up process" arrow>
                              <Select
                                {...field}
                                displayEmpty
                                style={{ textAlign: 'left' }} // Aligns text within dropdown
                                label="Subscription Plan Term" // Matches InputLabel
                                disabled
                              >
                                <MenuItem value="1_YEAR">1 Year</MenuItem>
                              </Select>
                            </Tooltip>
                          )}
                        />
                        <FormHelperText>{errors.planTerm?.message}</FormHelperText>
                      </FormControl>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: 30 }}>
                      <FormControl fullWidth error={Boolean(errors.planType)}>
                        <InputLabel>Subscription Plan Type</InputLabel>
                        <Controller
                          name="planType"
                          control={control}
                          rules={{ required: "Plan Type is required" }}
                          defaultValue="BASIC"
                          render={({ field }) => (
                            <Tooltip title="This Field is disable,You can Upgrade your plan type by contacting the System admin after completed the Sign-Up process" arrow>
                              <Select
                                {...field}
                                displayEmpty
                                style={{ textAlign: 'left' }} // Aligns text within dropdown
                                label="Subscription Plan Type" // Matches InputLabel
                                disabled
                              >
                                <MenuItem value="BASIC">Basic</MenuItem>
                              </Select>
                            </Tooltip>
                          )}
                        />
                        <FormHelperText>{errors.planType?.message}</FormHelperText>
                      </FormControl>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: 30 }}>
                      <Controller
                        name="houseCount"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: { value: true, message: "House Count is required" },
                          pattern: { value: /^\d+$/, message: "Please provide valid house count (1 to 9999)" },
                          minLength: { value: 1, message: "Please provide minimum 1 digits" },
                          maxLength: { value: 4, message: "Please provide maximum of 4 digits only" },
                        }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label="House Count*"
                            onBlur={(e) => field.onChange(e.target.value.trim())}
                            error={Boolean(errors?.houseCount?.message)}
                            helperText={errors?.houseCount?.message}
                            inputRef={houseCountRef}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Prevent the user from entering only '0'
                              if (value === "0") {
                                return;
                              }
                              // Update the form value and trigger the calculation
                              field.onChange(e);
                              handleHouseCountChange(value);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ marginTop: 30 }}>
                      <Controller
                        name="totalSubscriptionAmount"
                        control={control}
                        defaultValue=""

                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            disabled
                            label="Total Subscription Amount*"
                            error={Boolean(errors?.totalSubscriptionAmount?.message)}
                            helperText={errors?.totalSubscriptionAmount?.message}
                            InputProps={{
                              readOnly: true, // Make the field read-only since it's calculated
                            }}
                          />
                        )}
                      />
                    </div>

                  </div>
                  &nbsp;
                  <div className="flex justify-center">
                  <span style={{alignItems:'center',alignSelf:'center'}}>
                  <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={'6Lc6O5AqAAAAAAAiMlNjiSQNK1K-wsispctL-Ebh'}
                      onChange={onRecaptchaChange}
                    />
                  </span>
                  {captchaError && <p style={{ color: 'red',fontSize:'13px' }}>{captchaError}</p>}
                   
                  </div>
                  <div style={{ marginTop: 30, textAlign: "center" }}>

                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  </div>
                </form>
                <br /><br /><br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeContact;
