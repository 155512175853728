import React, { useCallback, useEffect, useState } from 'react'
import { BASE_URL, ABOUT, IMAGE } from '../utils/ApplicationEndpoints'
import axios from 'axios'
import { ArrowForward } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';


function HomeAbout(props) {
  const [data, setData] = useState([])
  const [imageData, setImageData] = useState('');


  
  const fetchData = useCallback(async () => {
    console.log("fetch data ");
    try {
      const response = await axios.get(BASE_URL+ ABOUT);
      setData(response.data[0]);
      setImageData(response.data[0].imageurl);
      
      console.log("alt check"+response.data[0].imgAlternative)
      
    } catch (error) { 
      console.log(error);
    }
  }, []);

useEffect(() => {

  fetchData()
  
}, [fetchData])

const location = useLocation();

useEffect(() => {
  // Scroll to the top when the location changes
  window.scrollTo(0, 0);
}, [location]);
const  isUsRoute = location.pathname.startsWith('/us');
const isInRoute=location.pathname.startsWith('/in');

  return (
    <React.Fragment>

    <section id="about" className="ud-about">
      
      <div className="container">
        <div className="ud-about-wrapper wow fadeInUp" data-wow-delay=".2s">
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className="ud-about-content-wrapper">
            <div className="ud-about-content">
              <span style={{fontSize:"20px",fontWeight
            :"bold",color:'var(--primary-color)'}}>{data.topic}</span>
           
              <p style={{paddingTop:30}}>
            
{data.description}              </p>

             
<Link
                            to={"/signup"}
                             className="ud-main-btn ud-white-btn ud-main-btns"
                            replace
                            style={{
                              backgroundColor: "white",
                              color: "#2748b4",
                              marginLeft: 50,
                              padding: "8px 16px", 
                              fontSize: "14px", 
                            }}
                          >
                            Sign up
                          </Link>
               {/* <Button
                    
                    className="ud-main-btn ud-border-btn"
                    onClick={() => {
                      window.open(data.buttonurl, "_blank");
                    }}
                  >
                    Schedule a Demo
                  </Button> */}
            </div>
          </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className="ud-about-image" >
              {/* modified by mohan raj for HB-XXXX on 25-08-23 start */}
            <img  src={BASE_URL+IMAGE+`${imageData}`}
             alt={data.imgAlternative}  title={data.imgAlternative }
             loading='eager' 
             width={'ascualWidth'} height={'ascualHeight'}/>
             {/* modified by mohan raj for HB-XXXX on 25-08-23 end */}
          </div>

            </div>
          </div>
        
         
      
        </div>

        {props.readmore==="true" ? 
         <div className="ud-about-content-wrapper ud-about-contents  pt-2 pb-2" style={{textAlign:'center'}}>
          <div >
          <a href='/about' className='ud-about-content'>Read More <ArrowForward/> </a>
          {/* <Link to={isUsRoute ? '/us/about' : isInRoute ? '/in/about' : ''} className="ud-menu-scroll">Read More <ArrowForward/> </Link> */}
            </div>
        
       </div> : null}
       
       
      </div>
    </section>
    </React.Fragment>
  )
}

export default HomeAbout
